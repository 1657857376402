<template>
  <section class="gauge-preferences">
    <div class="gauge-preferences__notifications mb-4">
      <h3>
        Notifications
        <v-icon
          color="common"
          size="18"
          class="mt-n1"
          @click="handleHelp"
        >
          {{ $vuetify.icons.values.mrl_tip }}
        </v-icon>
      </h3>

      <v-row class="mb-3">
        <v-col
          cols="12"
          sm="8"
          class="pb-0"
        >
          <div class="gauge-preferences__notifications-options">
            <radio
              v-model="emailType"
              value="everyDay"
              label="Email me <strong>every day</strong> precipitation has been detected (by PRISM estimation) through the <strong>previous day</strong> at this gauge location."
              name="sendEmail"
              class="mt-2 mb-3"
            />
            <radio
              v-model="emailType"
              value="onThreshold"
              label="Email me when cumulative estimated precipitation <strong>passes the set threshold</strong> for this gauge."
              name="sendEmail"
              class="mb-3"
            />
            <div
              v-if="emailType === 'onThreshold'"
              class="ml-7 mb-3 mt-n2 font-italic"
            >
              {{ notificationCumulativeRain }} of precipitation has been estimated since {{ notificationCumulativeFrom }}.
            </div>
            <radio
              v-model="emailType"
              value="noEmail"
              name="sendEmail"
              label="Receive no email notifications (threshold applies to in-app only)."
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="3"
          offset-sm="1"
        >
          <label>Threshold (inches)</label>
          <v-text-field
            v-model="form.threshold"
            v-validate="'required|decimal:3|min_value:0'"
            class="gauge-preferences__threshold-input input-white"
            required
            outlined
            dense
            single-line
            data-vv-name="threshold"
            name="threshold"
            type="number"
            step="0.01"
            min="0"
            :disabled="disabledThreshold"
            hide-details
            :error-messages="errors.collect('threshold')"
            @blur="handleThresholdBlur"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="8"
        >
          <div class="gauge-preferences__share mb-3">
            <h3>Public Map</h3>
            <checkbox
              v-model="form.isPublic"
              class="mt-2 mb-4"
              label="Display <strong><em>only</em></strong> gauge location and observations."
              name="public"
              :disabled="disabled"
            />
            <checkbox
              v-model="form.publicProps"
              class="ml-6"
              label="Additionally display gauge images, descriptions and comments"
              name="publicAdditional"
              :disabled="!form.isPublic || disabled"
            />
          </div>
        </v-col>

        <v-col
          cols="12"
          sm="3"
          offset-sm="1"
        >
          <div class="gauge-preferences__units">
            <h3>Units</h3>

            <v-select
              v-model="form.units"
              outlined
              class="input-white"
              single-line
              hide-details
              dense
              :disabled="disabled"
              :items="unitItems"
            />
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import ExternalBind from '@/mixins/ExternalBind'
import { UNITS, NOTIFICATION_BASE_THRESHOLD } from '@/utils/constants'
import {
  dateToDay,
  subtractDays
} from '@/utils/dateUtils'
import { decimalDoublePad } from '@/utils/numUtils'
import { pick } from 'lodash'

export default {
  name: 'GaugePreferences',

  mixins: [
    ExternalBind
  ],

  props: {
    disabled: Boolean,
    gauge: {
      type: Object,
      default: () => ({})
    },
    notificationSettings: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      unitItems: UNITS.slice(),
      emailType: 'everyDay',
      noEmail: false,
      form: {},
      notificationCopy: {}
    }
  },

  computed: {
    disabledThreshold () {
      return this.emailType === 'everyDay'
    },
    notificationCumulativeRain () {
      return `${decimalDoublePad(
        this.notificationCopy.cumulativeRain
      ) || 0}"`
    },
    notificationCumulativeFrom () {
      // TODO: need to "minus" one day to keep in line with email
      // Check with JD to conslidate this type of logic
      return dateToDay(
        subtractDays(this.notificationCopy.startCumulatingFrom, 1)
      )
    }
  },

  created () {
    this.notificationCopy = { ...this.notificationSettings }
    const gaugePreferences = pick(this.gauge, ['isPublic', 'publicProps', 'units'])
    const notificationPrefereneces = pick(this.notificationSettings, ['threshold', 'sendEmail'])
    this.form = {
      isPublic: true,
      units: 'in',
      sendEmail: true,
      threshold: NOTIFICATION_BASE_THRESHOLD,
      ...gaugePreferences,
      ...notificationPrefereneces,
      publicProps: !!gaugePreferences.publicProps
    }

    // user submitted values are not being casted as numbers
    // if user changes threshold to 0.01 manually, it does not qualify as "everyday"
    if (this.form.threshold < 0.02 && this.form.sendEmail) {
      this.emailType = 'everyDay'
    } else if (this.form.sendEmail) {
      this.emailType = 'onThreshold'
    } else {
      this.emailType = 'noEmail'
    }
  },

  watch: {
    form: {
      handler (val) {
        this.mx_value = { ...val }
      },
      deep: true,
      immediate: true
    },
    emailType (val) {
      this.form.sendEmail = val !== 'noEmail'
      if (val === 'everyDay') {
        this.form.threshold = NOTIFICATION_BASE_THRESHOLD
      }
    }
  },

  methods: {
    handleHelp () {
      this.$dialogBus.$emit(
        'open-dialog',
        {
          type: 'NotificationsHelperDialog'
        }
      )
    },
    handleThresholdBlur (e) {
      const {
        target: {
          value = ''
        } = {}
      } = e

      if (!value || value < 0.01) {
        this.form.threshold = 0.01
      }
    }
  }
}
</script>

<style lang="sass">
  .gauge-preferences
    .v-input--is-disabled
      opacity: .5
    .checkbox__label
      line-height: 1em

    @media (max-width: $sm-breakpoint)
      .v-input
        max-width: 150px
</style>
