import Vue from 'vue'
import {
  UI_ADD_LOADING_STATE,
  UI_REMOVE_LOADING_STATE,
  HELPERS_MERGE,
  HELPERS_ADD,
  HELPERS_UPDATE,
  HELPERS_DELETE,
  USER_LOGOUT
} from '../mutation-types'
import helpersApi, { helpersNormalizer, formatHelperComposite } from '@/api/helpers'
import sendApi from '@/api/sendMessage'
import { uniq, merge, findIndex } from 'lodash'

/**
  result: [uid, uid],
  entities: {
    helpers: {
      uid: {
        ...shareData // ['sharedWithUid', 'sharedWithEmail', 'sharedWithName']
        gaugePreferences: ['uid--gid', 'uid--gid']
      }
    },
    gaugePreferences: {
      'uid--gid': {
        shareLevel,
        shareActive
      }
    }
  }
 */

const state = {
  result: [],
  entities: {
    helpers: {},
    gaugePreferences: {}
  }
}

const getters = {
  // returns array of helper objects
  getHelpers(state) {
    return state.result.map(uid => state.entities.helpers[uid])
  },
  // returns a single helpers by the helpers uid
  getHelperByUid: (state, getters) => (uid) => {
    return state.entities.helpers[uid]
  },
  // returns gauge preferences by composite === `uid-gid`
  getHelperGaugeByComposite: (state, getters) => (composite) => {
    return state.entities.gaugePreferences[composite]
  },
  // returns array of { ...gaugePreferences, ...gauge } based on a single helper uid
  getAllHelperGaugesByUid: (state, getters) => (uid) => {
    let { gaugePreferences = {} } = getters.getHelperByUid(uid)

    return gaugePreferences.reduce((outcome, composite) => {
      let [uid, gid] = composite.split('--')

      if (getters.getHelperGaugeByComposite(composite).shareLevel) {
        outcome.push({
          ...getters.getHelperGaugeByComposite(composite),
          ...getters.getGaugeById(gid)
        })
      }

      return outcome
    }, [])
  },
  // returns same as getAllHelperGaugesByUid but filters out shareActive !== true
  getActiveHelperGaugesByUid: (state, getters) => (uid) => {
    return getters.getAllHelperGaugesByUid(uid).filter(gauge => gauge.shareActive)
  },
  // return array of helper objects; pass additional boolean to filter by shareActive
  getHelpersByGid: (state, getters) => (gid, ifActive) => {
    return getters.getHelpers.reduce((outcome, helper) => {
      let { gaugePreferences = [], sharedWithUid: uid } = helper
      let composite = formatHelperComposite(uid, gid)
      let preferences = getters.getHelperGaugeByComposite(composite) || {}

      let pushToOutcome = (ifActive)
        ? gaugePreferences.includes(composite) && preferences.shareActive
        : gaugePreferences.includes(composite)

      pushToOutcome && outcome.push({ ...helper, ...preferences })

      return outcome
    }, [])
  }
}

const actions = {
  async fetchHelpers ({ commit, dispatch, getters }) {
    try {
      commit(UI_ADD_LOADING_STATE, 'loadingHelpers')
      const uid = getters.getUserId

      const response = await helpersApi.fetch(uid)

      commit(HELPERS_MERGE, helpersNormalizer(response))
      commit(UI_REMOVE_LOADING_STATE, 'loadingHelpers')
    } catch (e) {
      commit(UI_REMOVE_LOADING_STATE, 'loadingHelpers')
      throw e
    }
  },

  async updateHelper ({ dispatch, commit }, payload) {
    const response = await helpersApi.put(payload)

    commit(HELPERS_UPDATE, response)
    return response
  },

  async deleteHelper ({ dispatch, commit }, { uid, sharedWithUid }) {
    try {
      commit(UI_ADD_LOADING_STATE, 'removingHelper')
      await helpersApi.delete({ uid, sharedWithUid })
      commit(HELPERS_DELETE, sharedWithUid)

      return
    } finally {
      commit(UI_REMOVE_LOADING_STATE, 'removingHelper')
    }
  },

  async notifyHelper ({ dispatch, commit}, payload) {
    const {
      email,
      message
    } = payload

    await sendApi.sendMessage(email, message, 'MyRAINge Log Helper Notification', 'ses')
  }
}

const mutations = {
  [HELPERS_MERGE](state, { result, entities }) {
    state.result = uniq(state.result.concat(result)).slice()
    state.entities = merge({}, state.entities, entities)
  },
  [HELPERS_ADD](state, helper) {
    let { id } = helper

    Vue.set(state.result, state.result.length, id)
    Vue.set(state.entities, id, helper)
  },
  [HELPERS_UPDATE](state, { result, entities }) {
    let [uid] = result
    
    let { 
      entities: {
        helpers: {
          [`${uid}`]: {
            gaugePreferences: existingPreferences = []
          } = {}
        } = {}
      } = {}
    } = state

    let {
      helpers: {
        [`${uid}`]: {
          gaugePreferences: newPreferences = []
        } = {}
      } = {}
    } = entities

    entities.helpers[uid].gaugePreferences = uniq(newPreferences.concat(existingPreferences))

    state.result = uniq(state.result.concat(result)).slice()
    state.entities = merge({}, state.entities, entities)
  },
  [HELPERS_DELETE](state, sharedWithUid) {
    let index = findIndex(state.result, (innerId) => { return sharedWithUid === innerId })

    if (index !== -1) {
      Vue.delete(state.result, index)
      Vue.delete(state.entities.helpers, sharedWithUid)
    }
  },
  [USER_LOGOUT] (state, payload) {
    state.result = []
    state.entities = {
      helpers: {},
      gaugePreferences: {}
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}