<template>
  <div :class="getClasses">
    <collapse ref="collapse" :value="open">
      <div
        tag="header"
        slot="trigger"
        class="d-flex align-center justify-space-between log-list-item__trigger">
        <template v-if="log.isReset">
          <div class="log-list-item__stat--observation">
            <span class="log-list-item__stat__title" v-if="baseLevel">Base level</span>
            <span class="log-list-item__stat__title" v-else>Reset to</span>
            <span class="log-list-item__stat__number">{{ reading }}</span>
          </div>
        </template>

        <template v-else>
          <div class="log-list-item__stat--observation">
            <span class="log-list-item__stat__title">Observation</span>
            <span class="log-list-item__stat__number">{{ reading }}</span>
          </div>

          <div class="log-list-item__stat--difference ml-auto">
            <span class="log-list-item__stat__title">Difference</span>
            <span class="log-list-item__stat__number">{{ record }}</span>
          </div>
        </template>

        <div class="d-flex align-center justify-end log-list-item__trigger__icons">
          <v-icon 
            v-if="log.helperUid"
            class="log-list-item__helper-icon mr-0" 
            size="18">{{ $vuetify.icons.values.mrl_login }}</v-icon>
          <v-icon 
            class="log-list-item__trigger__icon" 
            size="30">keyboard_arrow_down</v-icon>
        </div>

        <div class="log-list-item__date">{{ shortDate }}</div>
      </div>

      <div class="log-list-item__content py-2" slot="content">
        <template v-if="!editMode">

          <p class="log-list-item__content__notes mb-1" v-if="log.notes">{{ log.notes }}</p>

          <div class="log-list-item__entry" v-if="log.helperName">Helper: <strong>{{ log.helperName }}</strong></div>
          <div class="log-list-item__entry"><strong>{{ longDate }}</strong></div>

          <img
            v-if="form.image"
            class="log-list-item__content__img mb-2"
            :src="form.image"
            @load="handleImageLoaded"
            @click="handleImageClick(form.image)"
          >

          <div v-else class="log-list-item__content__img mb-2">No Image Uploaded</div>
          <v-btn
            v-if="allowEdit(log.helperUid)"
            text
            @click.native="toggleEditMode"
            class="mt-3 log-list-item__edit primary--text super-bare d-block">
            Edit Observation
          </v-btn>
          <v-btn
            v-if="allowEdit(log.helperUid)"
            text
            :to="{
              name: 'gaugeEmpty',
              params: {
                date: log.logDateTime
              }
            }"
            class="mt-1 log-list-item__edit primary--text super-bare">
            Add Reset
          </v-btn>
        </template>

        <template v-else>
          <div class="log-list-item__editing">
            <div
              v-if="error"
              class="form__errors"
            >
              {{ error }}
            </div>
            <date-menu
              v-validate="'validDate'"
              :value="form.logDateTime"
              name="date"
              label="Date of Reading"
              :error-messages="errors.collect('date')"
              data-vv-name="date"
              hide-actions
              dense
              :menu-props="{
                attach: true,
                nudgeRight: 8
              }"
              :text-field-props="{
                hideDetails: true,
                outline: true,
                singleLine: true
              }"
              @input="handleDatePick"
            />

            <v-text-field
              v-model="form.reading"
              v-validate="'required|decimal:3'"
              label="Inches"
              outlined
              required
              type="number"
              aria-required="true"
              data-vv-name="reading"
              name="reading"
              ref="reading"
              class="my-2"
              hide-details
              dense
              :error-messages="errors.collect('reading')"
              v-input-no-scroll="'reading'"
              aria-autocomplete="off"
              autocomplete="off"
            />

            <v-textarea
              v-model="form.notes"
              name="notes"
              outlined
              hide-details
              label="Add notes here"
              rows="2">
            </v-textarea>
 
            <image-input
              v-if="!readingInvalid"
              class="mb-2 mt-2"
              small 
              name="editImage" 
              :src="form.image"
              v-on:src-change="(value) => {form.image = value}" 
              v-model="form.newImage"/>

            <pill-alert
              v-if="readingInvalid"
              type="danger"
              size="small"
              class="mt-2"
            >
              {{ readingInvalid }}
            </pill-alert>

            <div
              class="log-list-item__editing-actions"
            >
              <v-btn
                type="submit"
                color="accent"
                class="log-list-item__submit"
                depressed
                rounded
                :loading="loading"
                small
                @click.native="formSubmit(updateLog)"
              >
                Save Log
              </v-btn>
              <a href="" @click.prevent="deleteLog" v-if="allowDelete(log.helperUid)">DELETE</a>
              <a href="" @click.prevent="toggleEditMode">CANCEL</a>
            </div>
          </div>
        </template>
      </div>
    </collapse>
  </div>
</template>

<script>
import { NETWORK_ERROR_SMALL } from '@/utils/constants'
import { mapGetters } from 'vuex'
import FormValidation from '@/mixins/FormValidation'
import GaugePermissions from '@/views/Gauges/mixins/GaugePermissions'
import { 
  dateToUTC,
  dateToLocal,
  dateToMonth,
  dateToFull,
  dateToTime
} from '@/utils/dateUtils'
import { decimalDoublePad } from '@/utils/numUtils'

export default {
  name: 'log-list-item',

  watch: {
    'readingInvalid'() {
      setTimeout(() => {
        this.$refs.collapse.$refs.contentWrapper.click()
      }, 100)
    },
    'form.newImage'() {
      setTimeout(() => {
        this.$refs.collapse.$refs.contentWrapper.click()
      }, 100)
    },
    log() {
      // TODO: figure out why reactivty doesn't work for the images
      this.form = {...this.form, ...this.log}
    }
  },

  props: {
    log: {
      type: Object,
      required: true
    },
    // @todo use these to add min/max validation rules
    previousLog: {
      type: Object,
      default: () => ({})
    },
    nextLog: {
      type: Object,
      default: () => ({})
    },
    baseLevel: Boolean,
    open: Boolean
  },

  mixins: [GaugePermissions, FormValidation],

  data() {
    return {
      editMode: false,
      error: '',
      form: {
        gid: this.log.gid,
        lid: this.log.lid,
        image: this.log.image,
        newImage: '',
        logDateTime: this.log.logDateTime,
        reading: decimalDoublePad(this.log.reading), // TODO: throwing toString errors
        notes: this.log.notes
      }
    }
  },

  computed: {
    ...mapGetters(['getUserId']),
    getClasses() {
      let {
        isReset,
        toBeSynced
      } = this.log || {}

      return {
        'log-list-item': true,
        'log-list-item--reset': isReset,
        'log-list-item--sync': toBeSynced 
      }
    },
    getPreviousLog () {
      return this.previousLog || {}
    },
    getNextLog () {
      return this.nextLog || {}
    },
    readingInvalid () {
      if (!this.form.reading) return false
      if (
        this.getPreviousLog.gid &&
        (this.form.reading < this.getPreviousLog.reading) &&
        !this.log.isReset
      ) {
        return `Reading must be larger than previous observation of ${this.getPreviousLog.reading}"`
      } else if (
        this.getNextLog.gid &&
        (this.form.reading > this.getNextLog.reading) &&
        !this.getNextLog.isReset
      ) {
        return `Reading must be smaller than next observation of ${this.getNextLog.reading}"`
      } else if (
        this.getNextLog.gid &&
        this.getNextLog.isReset &&
        (this.form.reading < this.getNextLog.reading)
      ) {
        return `Reading must be larger than next reset of ${this.getNextLog.reading}"`
      } else if (
        this.getPreviousLog.gid &&
        this.log.isReset &&
        (this.form.reading > this.getPreviousLog.reading)
      ) {
        return `Reset value must be less than previous observation of ${this.getPreviousLog.reading}"`
      } else {
        return false
      }
    },
    shortDate() {
      return dateToMonth(this.log.logDateTime)
    },
    longDate() {
      return dateToFull(this.log.logDateTime)
    },
    record() {
      return decimalDoublePad(this.log.record) + '"'
    },
    cumulative() {
      return decimalDoublePad(this.log.cumulative) + '"'
    },
    reading() {
      return decimalDoublePad(this.log.reading) + '"'
    }
  },

  methods: {
    handleImageClick (imgSrc) {
      this.$dialogBus.$emit('open-dialog', {
        type: 'FullImageDialog',
        props: {
          imgSrc
        }
      })
    },
    updateLog() {
      let vm = this

      let payload = Object.assign({}, this.form, {
        uid: this.getUserId,
        logDateTime: dateToUTC(this.form.logDateTime)
      })

      this.$store.dispatch('updateLog', payload).then(() => {
        // recalculate and resort logs after log has been edited
        setTimeout(() => {
          this.$refs.collapse.$refs.contentWrapper.click()
        }, 100)
        this.$store.dispatch('massageLogs', vm.log.gid)
        this.editMode = false
        this.loading = false
      }).catch(error => { 
        this.error = NETWORK_ERROR_SMALL
        this.loading = false 

        setTimeout(() => {
          this.$refs.collapse.$refs.contentWrapper.click()
        }, 100)
        console.log(error)
      })
    },
    handleImageLoaded(val) {
      // if this log item is auto open
      // AND image loads in, force collapse open
      this.open && this.$refs.collapse.$refs.contentWrapper.click()
    },
    deleteLog() {
      this.$dialogBus.$emit('open-dialog', {
        type: 'LogDeleteDialog',
        props: {
          log: this.log
        }
      })
    },
    allowEdit(helperUid) {
      // user can edit log if they are owner OR they are contirbutor and they created the log
      return this.editor || (this.contributor && (this.getUserId === helperUid))
    },
    allowDelete(helperUid) {
      return this.owner || (this.editor && helperUid)
    },
    handleDatePick(value) {
      const originalTime = dateToTime(this.log.logDateTime)
      this.form.logDateTime = dateToLocal(`${value} ${originalTime}`, ['MM/DD/YYYY HH:mm:ss'])
    },
    toggleEditMode() {
      this.editMode = !this.editMode
      setTimeout(() => {
        this.$refs.collapse.$refs.contentWrapper.click()
      }, 10)
    }
  }
}
</script>

<style lang="sass" src="./LogListItem.sass"></style>
