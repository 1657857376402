import Vue from 'vue'
import Auth from '@aws-amplify/auth'
import authApi from '@/api/auth'
import {
  USER_LOGOUT,
  USER_LOGIN,
  USER_SET_USER,
  USER_UPDATE_ATTRS,
  UI_ADD_LOADING_STATE,
  UI_REMOVE_LOADING_STATE
} from '@/store/mutation-types'
import LocalStorage from '@/api/localstorage'
import jscookie from 'js-cookie'
import mailChimpApi from '@/api/mailChimp'
import SendMessageApi from '@/api/sendMessage'
import * as Sentry from '@sentry/vue'
import uuid from 'uuid'
import router from '@/router'
import { normalizeAwsNameValuePair } from '@/utils/generalUtils'

export const USER_POOL_ID_STORAGE_KEY = 'mrl-user-pool-id'

// initial state
const state = {
  email: '',
  name: '',
  user: {}
}

const getters = {
  getToken(state) {
    let {
      signInUserSession = {}
    } = state.user

    if (signInUserSession === null) return ''

    let {
      idToken: {
        jwtToken
      } = {}
    } = signInUserSession

    return jwtToken
  },
  isAuthenticated(state, getters) {
    return !!getters.getToken
  },
  getUserAttribute: (state) => (attributeName) => {
    let {
      attributes = {}
    } = state.user

    return attributes[attributeName] || ''
  },
  getUserId(state) {
    return state.user.username
  },
  getUserFullName(state, getters) {
    return getters.getUserAttribute('name')
  },
  getUserEmail(state, getters) {
    return getters.getUserAttribute('email')
  },
  isUserEmailVerified(state, getters) {
    return getters.getUserAttribute('email_verified')
  }
}

const actions = {
  async checkAndGetUserAuth({ dispatch, commit, getters }) {
    commit(UI_ADD_LOADING_STATE, 'checkingAuth')
    // amplify auth offline
    // https://github.com/aws-amplify/amplify-js/issues/1006
    try {
      const user = await Auth.currentAuthenticatedUser()

      // Auth.currentAuthenticatedUser() doesn't return attributes if offline
      // pull from localstorage if that is the case
      if (!user.attributes) {
        user.attributes = LocalStorage.user.getUserAttrs()
      }

      console.log("Current Auth User: ", user)

      commit(USER_SET_USER, user)

      // set context for error reporting
      if (getters.getUserEmail) {
        dispatch('setSentryUser', { email: getters.getUserEmail })
      }

      return user
    } catch(error) {
      console.warn(error)
      
      return false
    } finally {
      commit(UI_REMOVE_LOADING_STATE, 'checkingAuth')
    }
  },

  async login({ dispatch, commit }, payload = {}) {
    try {
      const { username, password, rememberMe } = payload

      if (!username || !password) throw new Error('Login missing required parameters')

      // Auth.signIn doesn't return attributes
      // need to make separate call and then merge into the user object
      const user = await Auth.signIn(username, password)
      const attributes = normalizeAwsNameValuePair(await Auth.userAttributes(user))
      const mergedUser = Object.assign(user, { attributes })

      // as an offline backup since amplify auth doesnt retrieve attrs offline
      LocalStorage.user.setUserAttrs(attributes)
      jscookie.set(USER_POOL_ID_STORAGE_KEY, mergedUser.username)

      console.log("Logged In: ", mergedUser)

      if (rememberMe) await dispatch('rememberDevice', user)
      else await dispatch('forgetDevice', user)

      commit(USER_SET_USER, mergedUser)
      commit(USER_LOGIN)
    } catch (error) {
      let { message } = error
      console.error(message)
      throw new Error(message)
    }
  },

  async logout({ commit }) {
    try {
      await Auth.signOut()

      // clear users attributes locale storage
      LocalStorage.user.setUserAttrs({})
      jscookie.remove(USER_POOL_ID_STORAGE_KEY)

      commit(USER_LOGOUT)

      setTimeout(() => {
        if (window) {
          window.location.reload()
        }
      }, 100)
      return
    } catch(error) {
      console.error(error)
      throw new Error(error)
    }
  },

  setSentryUser (store, payload) {
    Sentry.setUser(payload)
  },

  async signUp({ commit, dispatch, getters}, credentials) {
    try {
      const {
        name,
        password,
        email
      } = credentials
      const attributes = { email, name }
      const emailExists = await dispatch('checkIfEmailExists', email)

      if (emailExists) throw new Error('This email address is already in use')

      let response = await Auth.signUp({
        username: uuid.v1(),
        password,
        attributes
      })

      let { user } = response

      commit(USER_SET_USER, Object.assign(user, { attributes }))
      console.log("Unconfirmed User: ", user)

      // /confirm?user=<userId>
      router.replace({
        name: 'Confirm',
        query: { user: getters.getUserId }
      })

      // TODO: this can be managed in MailChimp
      // dispatch('sendNotificationUserActivity', {
      //   email,
      //   subject: "MyRAINge Log - New User Signup", 
      //   message: "A new member has signed up for MyRAINge Log!", 
      //   oldEmail: null
      // })

      return
    } catch(error) {
      const { message } = error
      console.error(message)
      throw new Error(message)
    }
  },

  async checkIfEmailExists({}, email) {
    try {
      // fake a login, hoping to catch a "UserNotFoundException"
      const password = "e0j4a61B0nCTtUTjVHiYJ_ElRbMZ_NcVF1ajYvp9pjOndOu1ayooNa7Hs9meUT"
      await Auth.signIn(email, password)

      return true
    } catch(error) {
      return (error.code === 'UserNotFoundException')
        ? false
        : true
    }
  },

  async confirmRegistration({}, { userId, code }) {
    try {
      // https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmSignUp.html#CognitoUserPools-ConfirmSignUp-request-ForceAliasCreation
      // in the off chance a user account is being setup with an already confirmed email address
      // forceAliasCreation will migrate the alias from the previous user to the new user being created
      await Auth.confirmSignUp(userId, code, {
        forceAliasCreation: true
      })
      
      // TODO: should actions be responsible for route changes?
      router.push({ 
        name: 'login', 
        params: { success: 'Account verification success! Please login to begin.' }
      })

      return
    } catch(error) {
      let { message } = error
      console.error(error)
      throw new Error(message)
    }
  },

  // resends the code from original user registration if user lost code
  async resendConfirmRegistration(store, userId) {
    try {
      await Auth.resendSignUp(userId)

      return
    } catch (error) {
      let { message } = error
      console.error(error)
      throw new Error(message)
    }
  },

  async forgotPassword({}, email) {
    try {
      await Auth.forgotPassword(email)

      return
    } catch (error) {
      const { message } = error
      console.error(message)
      throw new Error(message)
    }
  },

  async forgotPasswordConfirm({}, payload) {
    try {
      const { email, code, new_password } = payload
      await Auth.forgotPasswordSubmit(email, code, new_password)

      // TODO: should actions be responsible for route changes?
      router.push({
        name: 'login', 
        params: { success: 'Your password has been successfully updated.' }
      })

      return
    } catch(error) {
      const { message } = error
      console.error(message)
      throw new Error(message)
    }
  },

  // he user requested to change their email
  async changeEmail({ dispatch, getters }, newEmail) {
    try {
      if (!newEmail) throw new Error('An email address must be provided')

      const oldEmail = getters.getUserEmail
      const emailExists = await dispatch('checkIfEmailExists', newEmail)
      
      if (emailExists) throw new Error('This email address is already in use')

      // if updating the email attribute:
      // Auth.updateUserAttributes() updates the attributes, sets email_verified = false and triggers attribute confirmation request
      await dispatch('updateUserAttributes', { email: newEmail })
      
      dispatch('sendNotificationUserActivity', {
        email: newEmail, 
        subject: "MyRAINge Log - User Email Changed", 
        message: "A user has changed their email for MyRAINge Log.", 
        oldEmail
      })

      mailChimpApi.updateSubscribedUser({ emailAddress: oldEmail, params: {
        email_address: newEmail
      }})

      return
    } catch (error) {
      const { message } = error
      console.error(error)
      throw new Error(message)
    }
  },

  // resend the code if the user changed their email and lost the code
  async resendEmailConfirmationCode({ getters }, payload) {
    try {
      const { password, userId } = payload

      // checking if the user is authenticated, use appropriate Amplify Auth api call
      if (getters.isAuthenticated) {
        // Auth.verifyCurrentUserAttribute() initiates an attribute confirmation request
        await Auth.verifyCurrentUserAttribute('email')
      } else {
        if (!password) throw new Error('Must provide password to confirm new email') 

        // if the user is not currently logged in, we ask for their password
        // sign them in and then used the returned user object for Auth.verifyUserAttribute

        // TODO: this is actually giving them an active session; how should we handle this
        let user = await Auth.signIn(userId, password)

        // Auth.verifyUserAttribute() initiates an attribute confirmation request
        await Auth.verifyUserAttribute(user, 'email')
      }
    } catch (error) {
      const { message } = error
      console.error(error)
      throw new Error(message)
    }
  },

  // TODO: name change!
  async confirmUserEmail({ getters }, payload) {
    try {
      const { code, password, userId } = payload

      if (!code) throw new Error('Verification code must be provided to confirm new email')
      var response

      // checking if the user is authenticated and using appropriate Amplify Auth api call
      if (getters.isAuthenticated) {
        response = await Auth.verifyCurrentUserAttributeSubmit('email', code)
      } else {
        if (!password) throw new Error('Must provide password to confirm new email') 

        // if the user is not currently logged in, we ask for their password
        // sign them in and then used the returned user object for Auth.verifyUserAttributeSubmit
        let user = await Auth.signIn(userId, password)
        response = await Auth.verifyUserAttributeSubmit(user, 'email', code)
      }

      console.log("Confirmed Email: ", response)

      return 
    } catch(error) {
      const { message } = error
      console.error(error)
      throw new Error(message)
    }
  },

  async updateUserAttributes({ commit }, attributes = {}) {
    try {
      const response = await Auth.updateUserAttributes(state.user, attributes)

      let { attributes: oldAttributes = {} } = state.user
      const newAttributes = Object.assign({}, oldAttributes, attributes)

      // as an offline backup since amplify auth doesnt retrieve attrs offline
      LocalStorage.user.setUserAttrs(newAttributes)

      console.log("Updated user attributes: ", response)
      commit(USER_UPDATE_ATTRS, newAttributes)

      return response
    } catch(error) {
      const { message } = error
      console.error(message)
      throw new Error(message)
    }
  },

  async getCognitoUser({ dispatch, commit }, email) {
    try {
      commit(UI_ADD_LOADING_STATE, 'gettingCognitoUser')

      let response = await authApi.getCognitoUser(email)

      commit(UI_REMOVE_LOADING_STATE, 'gettingCognitoUser')
      return response
    } catch(e) {
      const { message } = error
      console.error(message)
      throw new Error(message)
    }
  },
  
  sendNotificationUserActivity(store, payload) {
    // publish to topic to notify of new email
    if (payload) {
      let {email, subject, message, oldEmail} = payload;
      SendMessageApi.notifyNewUserSignup(email, subject, message, oldEmail)
    }
  },

  // this sets the initial state of "remembered / not remembered"
  // currently only uses this on login
  async rememberDevice(store, user) {
    try {
      const {
        state: {
          user: stateUser
        } = {}
      } = store

      const innerUser = user || stateUser

      const onFailure = (error) => { throw new Error(error) }
      const onSuccess = (result) => { console.log("DEVICE REMEMBERED: ", result) }

      innerUser.setDeviceStatusRemembered({ onSuccess, onFailure })

      return
    } catch (error) {
      const { message } = error
      console.error(message)
      throw new Error(message)
    }
  },
  
  // the device is already remembered and you want to forget it!
  async forgetDevice(store, user) {
    try {
      const {
        state: {
          user: stateUser
        } = {}
      } = store

      const innerUser = user || stateUser

      const onFailure = (error) => { throw new Error(error) }
      const onSuccess = (result) => { console.log("DEVICE NOT REMEMBERED: ", result) }

      innerUser.setDeviceStatusNotRemembered({ onSuccess, onFailure })

      return
    } catch (error) {
      const { message } = error
      console.error(message)
      throw new Error(message)
    }
  }
}

// mutations - must be syncronous
const mutations = {  
  [USER_SET_USER](state, user) {
    state.user = user
  },

  [USER_UPDATE_ATTRS](state, attributes) {
    Vue.set(state.user, 'attributes', attributes)
  },

  [USER_LOGIN] (state) {
    // :)
  },
  
  [USER_LOGOUT] (state) {
    state.user = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
